import _ from 'lodash';
import * as React from 'react';
import s from './ProductList.scss';

export interface PaginationLinksForSeoProps {
  currentPage: number;
  totalPages: number;
  url: string;
}

function getRelByCurrentPage(currentPage: number, index: number, last: number) {
  if (index + 1 === currentPage) {
    return 'prev';
  }

  if (index - 1 === currentPage) {
    return 'next';
  }

  if (index === 1) {
    return 'first';
  }

  if (index === last) {
    return 'last';
  }

  return undefined;
}

function getHerfUrl(url: string, index: number): string {
  const splittedParams = url.split('?');
  const baseUrl = splittedParams[0];
  const urlParams = splittedParams.length > 1 ? splittedParams[1] : '';

  const removePrefixPageParamRegex = /&?page=[0-9]+&?/i;
  const removePageParamRegex = /&?page=[0-9]+/i;
  const isPageFirstParam = urlParams.startsWith('page=');

  const urlParamsWithoutPage = urlParams.replace(
    isPageFirstParam ? removePrefixPageParamRegex : removePageParamRegex,
    ''
  );
  return `${baseUrl}?${urlParamsWithoutPage}${urlParamsWithoutPage ? '&' : ''}page=${index}`;
}

function getHrefNode(currentPage: number, index: number, last: number, url: string) {
  const rel = getRelByCurrentPage(currentPage, index, last);
  const herfUrl = getHerfUrl(url, index);
  return (
    <a href={herfUrl} data-hook="product-list-pagination-link-seo-link" rel={rel}>
      {`page ${index}`}
    </a>
  );
}

export const PaginationLinksForSeo: React.FunctionComponent<PaginationLinksForSeoProps> = (props) => {
  const {currentPage, totalPages, url} = props;

  return (
    <div data-hook="product-list-pagination-seo" className={s.paginationLinksForSeo}>
      {_.times(totalPages, (i) => getHrefNode(currentPage, i + 1, totalPages, url))}
    </div>
  );
};
